<template>
  <standard-layout v-if="cart">
    <template v-slot:main>
      <div class="mt-2">
        <div class="row list-group">
          <div class="list-group-item" v-for="position in cart.positions"
               :key="position.id"
               @click="handlePositionEdit(position)">
            <div class="d-flex">
              <div class="text-muted pr-2" v-if="isCartPositionReservationRelated(position)">
                <font-awesome-icon icon="edit" size="1x"/>
              </div>
              <div class="flex-grow-1">
                <div>
                  <div v-if="position.article.product.category === 'RES'"
                       class="product-image">
                    <cld-image :public-id="position.article.product.imageId" class="img-fluid"/>
                  </div>
                  <div>{{ position.article.product.name }}</div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-1">
              <div class="text-muted pr-2 d-flex" v-if="!isCartPositionReservationRelated(position)">
                <button class="btn btn-sm btn-outline-success"
                        @click="updateCartPosition({article: position.article, quantityAdjustment: 1})">
                  <font-awesome-icon icon="plus-circle" class="mr-1 img-fluid"/>
                  hinzufügen
                </button>
                <button v-if="quantity(position.article.id) > 0"
                        class="btn btn-sm btn-outline-danger ml-1"
                        @click="updateCartPosition({article: position.article, quantityAdjustment: -1})">
                  <font-awesome-icon icon="minus-circle" class="mr-1 img-fluid"/>
                  entfernen
                </button>
              </div>
              <div class="text-right">
              <span v-if="position.article.product.category !== 'RES'"
                    class="text-muted small pr-2">{{ position.quantity }}&times;{{
                  position.article.grossPrice | price
                }}</span>
                {{ position.totalGrossPrice | price }}
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col flex-grow-1 text-right">
            Dein Preis
          </div>
          <div class="col flex-grow-0 font-weight-bold">
            {{ cart.totalGrossPrice | price }}
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right small text-muted text-right">
            {{ pricePerPerson|price }} / Person
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right mt-2">
            <button class="btn btn-primary" @click="$router.push({name: 'confirmBooking', params: {cart:cart.id}})">
              Weiter
            </button>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <hr/>
        <div class="h5">Was können wir noch für Dich tun?</div>
        <div class="scrollable-list">
          <div v-for="article in articles"
               :key="'article_' + article.id"
               class="border-bottom pb-2">
            <div class="row">
              <div class="col-9 font-weight-bolder">
                {{ article.product.name }}
              </div>
              <div class="col-3 text-right pr-3">
                <div v-if="article.product.pricingType === 'byAttendees'">
                  <div>{{ (article.grossPrice * cart.attendees) | price }}</div>
                </div>
                <div v-else>
                  {{ article.grossPrice | price }}<span v-if="article.product.pricingType === 'byHour'">/h</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-secondary small">
                {{ article.product.description }}<br/>
                <span v-if="article.product.detailId" class="link"
                      @click="openDetail(article.product.detailId, article.product.name)">
              <font-awesome-icon icon="info-circle"/>&nbsp;mehr dazu
            </span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col d-flex text-nowrap">
                <button class="btn btn-sm btn-outline-success"
                        @click="updateCartPosition({article, quantityAdjustment: 1})">
                  <font-awesome-icon icon="plus-circle" class="mr-1"/>
                  hinzufügen
                </button>
                <div v-if="quantity(article.id) > 0">
                  <button class="btn btn-sm btn-outline-danger ml-1"
                          @click="updateCartPosition({article, quantityAdjustment: -1})">
                    <font-awesome-icon icon="minus-circle" class="mr-1"/>
                    entfernen
                  </button>
                </div>
              </div>
              <div class="col font-weight-bolder text-right"
                   v-if="quantity(article.id) > 0 && article.product.pricingType !== 'byAttendees'">
                {{ quantity(article.id) }}&times;{{ article.grossPrice | price }}
              </div>
              <div class="col font-weight-bolder text-right" v-if="quantity(article.id) > 0">
                {{ (quantity(article.id) * article.grossPrice) | price }}
              </div>
            </div>
          </div>
          <div v-if="isDetailVisible">
            <modal :title="detail.title"
                   @close="isDetailVisible = false">
              <template v-slot:body>
                <cld-image v-if="detail.image" class="img-fluid" :public-id="detail.image"/>
                <div v-html="detail.content" class="mt-2"/>
              </template>
              <template v-slot:footer>
                <button type="button" class="btn btn-secondary" @click="isDetailVisible = false">OK</button>
              </template>
            </modal>
          </div>
        </div>
      </div>
    </template>
  </standard-layout>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {arrayQueryString} from "@/utils/axios-utils";
import Modal from "@/components/generic/Modal";

export default {
  components: {Modal},
  data() {
    return {
      articles: [],
      detail: {},
      isDetailVisible: false
    }
  },
  computed: {
    ...mapGetters(['cart', 'reservationPrice', 'reservation']),
    pricePerPerson() {
      return this.cart.totalGrossPrice / this.cart.attendees
    }
  },
  methods: {
    ...mapActions(['updateCartPosition', 'initCart']),
    async init() {
      console.log("init")
      if (!this.cart) {
        if (this.$route.query.cart) {
          await this.$store.dispatch('initCart', this.$route.query.cart)
              .catch(error => {
                this.handleError(error)
                this.$router.push({name: 'editReservation'})
              })
        } else {
          this.$router.push({name: 'editReservation'})
        }
      }
      console.log('resArt: ' + JSON.stringify(this.cart.startTime))
      let validOnDate = this.cart?.startTime ? this.cart.startTime : null
      if (validOnDate) {
        const params = {
          channel: process.env.VUE_APP_SPABOOT_CHANNEL_ID,
          categories: arrayQueryString(['REN', 'WEL'])
        }
        if (validOnDate)
          params.valid = validOnDate.toFormat("yyyy-MM-yy")
        await this.$api.get('/articles', {
          params: params
        }).then(response => this.articles = response.data)
      }
    },
    quantity(articleId) {
      let res = 0
      this.cart.positions.forEach(cp => {
        if (cp.article.id === articleId)
          res += cp.quantity
      })
      return res
    },
    openDetail(detailId, title) {
      this.$api.get('/product-details/' + detailId)
          .then(response => {
            this.detail = {...response.data, title}
            this.isDetailVisible = true
          })
          .catch(error => this.handleError(error))
    },
    isCartPositionReservationRelated(cartPosition) {
      const productCategory = cartPosition.article.product.category
      return productCategory === 'RES' || productCategory === 'PER'
    },
    handlePositionEdit(position) {
      if (this.isCartPositionReservationRelated(position)) {
        const route = {
          name: 'editReservation'
        }
        if (this.reservation.number)
          route.params = {
            number: this.reservation.number
          }
        this.$router.push(route)
      }
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style scoped>
.scrollable-list {
  font-size: smaller;
}

.product-image {
  max-width: 40%;
}
</style>
